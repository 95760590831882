import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { useMemo, useState } from 'react'

import {
  getDiscountedPrice,
  getUsPrice,
  priceWrapper,
} from '../../pages/product/addProduct'

const HEAD_CELL = [
  '엑셀 행 번호',
  '처리상태',
  '실패사유',
  '카테고리(대)',
  '카테고리(중)',
  '상품명',
  '판매가',
  '해외판매가',
  '해외할인판매가',
  '옵션여부',
  '재고',
]

const ROW_PER_PAGE = 10

const BulkProductTable = ({ products }) => {
  const [page, setPage] = useState(0)
  const emptyRows = Math.max(0, (1 + page) * ROW_PER_PAGE - products.length)

  const visibleRows = useMemo(() => {
    return products.slice(ROW_PER_PAGE * page, ROW_PER_PAGE * (page + 1))
  }, [products, page])

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table>
          <BulkTableHeader />
          <TableBody>
            {visibleRows.map((product, index) => {
              return (
                <BulkProductItem product={product} key={`product_${index}`} />
              )
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={HEAD_CELL.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[ROW_PER_PAGE]}
        component="div"
        count={products.length}
        rowsPerPage={ROW_PER_PAGE}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
      />
    </Box>
  )
}

export default BulkProductTable

const BulkTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        {HEAD_CELL.map((data) => {
          return (
            <TableCell id={data} key={data} align="center">
              {data}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

const BulkProductItem = ({ product }) => {
  return (
    <TableRow>
      <TableCell align="center">{product?.cellNumber}</TableCell>
      <TableCell
        align="center"
        sx={{
          color: product?.status === '성공' ? '#00a52d' : '#ff0000',
          fontWeight: 700,
        }}
      >
        {product?.status}
      </TableCell>
      <TableCell align="center">{product?.reason}</TableCell>
      <TableCell align="center">{product?.categoryBig?.korName}</TableCell>
      <TableCell align="center">{product?.category?.korName}</TableCell>
      <TableCell align="center">{product?.name}</TableCell>
      <TableCell align="center">
        {product?.status === '성공' &&
          `${priceWrapper(product?.sellingPrice)}원`}
      </TableCell>
      <TableCell align="center">
        {product?.status === '성공' && `$ ${product?.price}`}
      </TableCell>
      <TableCell align="center">
        {product?.status === '성공' &&
          `$ ${getUsPrice(
            getDiscountedPrice(
              `${product?.sellingPrice}`,
              product.discountAmount,
              product.discountType
            ),
            product?.isProxy
          )}`}
      </TableCell>
      <TableCell align="center">{product?.existOption ? 'Y' : 'N'}</TableCell>
      <TableCell align="center">{priceWrapper(product?.count)}</TableCell>
    </TableRow>
  )
}
